import { Route, Routes } from "react-router-dom";
import Organisations from "../organisations/Organisations";
import Endpoint from "../endpoint/Endpoint";
import Migrate from "../migrate/Migrate";
import Connect from "../connect/Connect";
import Template from "../template/Template";
import Schema from "../schema/Schema";

const Body = () => {
    return(
        <div>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Organisations />
                    } />
                <Route
                    path="/migrate"
                    element={
                        <Migrate />
                    }
                />
                <Route
                    path="/connect"
                    element={
                        <Connect />
                    }
                />
                <Route 
                    path="/template"
                    element={
                        <Template />
                    }
                />
                <Route 
                    path="/schema"
                    element={
                        <Schema />
                    }
                />
                <Route 
                    path="/orgpath/:endname"
                    element={
                        <Endpoint />
                    }
                />
            </Routes>
        </div>
    )
}

export default Body;