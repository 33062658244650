import { Box, Button, Container, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { ChangeEvent, useEffect, useState } from "react";

import styles from "./Migrate.module.scss";
import { IFieldAdd, INewEndpoint, IOrganisation } from "../../types/apitypes";
import { fetch_organisations, fetch_yookr_paths, post_organisation_path } from "../../api/api";

const Migrate = () => {

    // variables
    const [organisations, setOrganisations] = useState<IOrganisation[]>([]);
    const [yookrpaths, setYookrpaths] = useState<string[]>([]);
    const [addedFields, setAddedFields] = useState<Map<string, string>>(new Map());
    const [fieldName, setFieldName] = useState<string>('');
    const [fieldValue, setFieldValue] = useState<string>('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState("");

    // handle selecting organisation
    const [selectedOrg, setSelectedOrg] = useState('');
    const handleSelectOrg = (event: SelectChangeEvent) => {
        setSelectedOrg(event.target.value as string);
    };

    // handle selecting connector endpoint
    const [selectedYookrpath, setSelectedYookrpath] = useState('');
    const handleSelectYookrpath = (event: SelectChangeEvent) => {
        setSelectedYookrpath(event.target.value as string);
    };

    // handle adding endpoint
    const [endpoint, setEndpoint] = useState('');
    const handleEndpointChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEndpoint(event.target.value as string);
    }

    // handle selecting data type
    const [dataType, setDataType] = useState('json');
    const handleSelectDataType = (event: SelectChangeEvent) => {
        setDataType(event.target.value);
    }

    // handle adding time fields
    const [startname, setStartname] = useState('');
    const [endname, setEndname] = useState('');
    const [selectedQueryType, setSelectedQueryType] = useState('');
    const [selectedTimeFormat, setSelectedTimeFormat] = useState('');
    const handleStartnameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setStartname(event.target.value);
    }
    const handleEndnameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEndname(event.target.value);
    }
    const handleSelectQueryType = (event: SelectChangeEvent) => {
        setSelectedQueryType(event.target.value);
    }
    const handleSelectTimeFormat = (event: SelectChangeEvent) => {
        setSelectedTimeFormat(event.target.value);
    }

    // adding fields to endpoint
    const handleAddField = () => {
        addFieldValuePair(fieldName, fieldValue);
        setFieldName('');
        setFieldValue('')
    };
    const addFieldValuePair = (field:string, value:string) => {
        const newMap = new Map(addedFields);
        newMap.set(field, value);
        setAddedFields(newMap);
    };
    const handleFieldNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFieldName(event.target.value);
    };
    const handleFieldValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFieldValue(event.target.value);
    };

    // post new endpoint
    const handleAddingEndpoint = async () => {
        const newEndpoint: INewEndpoint = {
            yookrpath: selectedYookrpath,
            orgpath: endpoint,
            datatype: dataType,
            startname: startname ? startname : 'none',
            endname: endname ? endname : 'none',
            querytype: selectedQueryType,
            format: selectedTimeFormat,
            addedFields: Array.from(addedFields.entries()).map(([field, value]): IFieldAdd => ({
                field,
                value,
            })),
        }
        const result = await post_organisation_path(newEndpoint);
        if (result.ok) {
            setError('');
            setSuccess('successfully added endpoint to migration');
        } else {
            setSuccess('');
            setError(result.error);
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            const [organisationsResult, yookrpathsResult] = await Promise.all([
                fetch_organisations(),
                fetch_yookr_paths(selectedOrg)
            ]);
            if (organisationsResult.ok) {
                setOrganisations(organisationsResult.value);
                if (yookrpathsResult.ok) {
                    setYookrpaths(yookrpathsResult.value);
                } else {
                    if (selectedOrg !== "") {
                        setError(yookrpathsResult.error);
                    }
                }
            } else {
                setError(organisationsResult.error);
            }
        }
        fetchData();

    }, [selectedOrg])

    return (
        <Container 
            sx={{
                ...(success ? {border: "5px solid #1fbe21", borderRadius: "10px"} : {}),
                ...(error ? {border: "5px solid red", borderRadius: "10px"} : {})
            }} 
            className={styles.container} 
            maxWidth="lg"
        >
            { success && 
                <p className={styles.success}>{success}</p>
            }
            { error && 
                <p className={styles.error}>{error}</p>
            }
            <h2 className={styles.title}>Organisation:</h2>
            <FormControl fullWidth>
                <InputLabel>Organisation</InputLabel>
                <Select
                    value={selectedOrg}
                    label="Organisation"
                    onChange={handleSelectOrg}
                >
                    {organisations.map((org) => (
                        <MenuItem key={org.organisationID} value={org.organisationID}>{org.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <h2 className={styles.title}>Connector Endpoint:</h2>
            <FormControl fullWidth>
                <InputLabel>Connector Endpoint</InputLabel>
                <Select
                    value={selectedYookrpath}
                    label="Connector Endpoint"
                    onChange={handleSelectYookrpath}
                >
                    {yookrpaths.map((yookrpath) => (
                        <MenuItem key={yookrpath} value={yookrpath}>{yookrpath}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <h2 className={styles.title}>Endpoint:</h2>
            <TextField 
                sx={{ width: "80%" }} 
                label="Endpoint" 
                variant="filled" 
                value={endpoint}
                onChange={handleEndpointChange}
            />
            <FormControl sx={{ width: "20%" }}>
                <InputLabel>Type</InputLabel>
                <Select
                    label="Type"
                    value={dataType}
                    onChange={handleSelectDataType}
                >
                    <MenuItem value="json">JSON</MenuItem>
                    <MenuItem value="csv">CSV</MenuItem>
                    <MenuItem value="influxdb">InfluxDB</MenuItem>
                    <MenuItem value="titledfields">Titled Fields</MenuItem>
                </Select>
            </FormControl>
            <h2 className={styles.title}>Time Fields:</h2>
            <Container>
                <TextField 
                    sx={{ width: "100%" }}
                    label="Start Name"
                    variant="filled"
                    placeholder="from"
                    value={startname}
                    onChange={handleStartnameChange}
                />
                <TextField 
                    sx={{ width: "100%", marginTop: "20px" }}
                    label="End Name"
                    variant="filled"
                    placeholder="until"
                    value={endname}
                    onChange={handleEndnameChange}
                />
                <FormControl sx={{ marginTop: "20px" }} fullWidth>
                    <InputLabel>Time Format</InputLabel>
                    <Select
                        value={selectedTimeFormat}
                        label="Time Format"
                        onChange={handleSelectTimeFormat}
                    >
                        <MenuItem value={"%Y"}>%Y</MenuItem>
                        <MenuItem value={"%Y-%m-%d"}>%Y-%m-%d</MenuItem>
                        <MenuItem value={"%m-%d-%Y"}>%m-%d-%Y</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ marginTop: "20px" }} fullWidth>
                    <InputLabel>Query Time</InputLabel>
                    <Select
                        value={selectedQueryType}
                        label="Query Time"
                        onChange={handleSelectQueryType}
                    >
                        <MenuItem value={"query"}>
                            Query Parameter (
                                {startname}={selectedTimeFormat}
                                {endname && `&${endname}=${selectedTimeFormat}`}
                            )
                        </MenuItem>
                    </Select>
                </FormControl>
            </Container>
            <h2 className={styles.title}>Added Fields:</h2>
            <Container>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: "100%" }} aria-label="added fields table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Field Name</TableCell>
                                <TableCell align="right">Field Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.from(addedFields.entries()).map(([field, value]) => (
                                <TableRow
                                    key={field}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{field}</TableCell>
                                    <TableCell align="right">{value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box display="flex" alignItems="center">
                    <TextField 
                        sx={{ flex: 1 }} 
                        label="Field Name" 
                        variant="filled"
                        value={fieldName}
                        onChange={handleFieldNameChange} 
                    />
                    <TextField 
                        sx={{ flex: 1 }} 
                        label="Field Value" 
                        variant="filled"
                        value={fieldValue}
                        onChange={handleFieldValueChange} 
                    />
                    <Button 
                        sx={{ flex: 1 }} 
                        variant="contained"
                        onClick={handleAddField}
                    >Add Field</Button>
                </Box>
            </Container>
            <Button 
                sx={{ margin: "20px 0 20px 0" }} 
                onClick={handleAddingEndpoint} 
                variant="contained"
            >Add Endpoint</Button>
        </Container>
    )
}

export default Migrate;