import { Button, Container, TextField } from "@mui/material";

import styles from './Template.module.scss';
import { ChangeEvent, useState } from "react";
import { INewTemplate } from "../../types/apitypes";
import { post_template } from "../../api/api";

const Template = () => {

    const [success, setSuccess] = useState('');
    const [error, setError] = useState("");

    // handle jwt token change
    const [templateJWT, setTemplateJWT] = useState('');
    const handleTemplateJWTChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTemplateJWT(event.target.value);
    }

    // handle json change
    const [templateJSON, setTemplateJSON] = useState('');
    const handleTemplateJSONChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTemplateJSON(event.target.value);
    }

    // handle add template
    const handleAddingTemplate = async () => {
        try {
            const jsonTemplateObject = JSON.parse(templateJSON);
            const jsonTemplateString = JSON.stringify(jsonTemplateObject);

            const new_template: INewTemplate = {
                templateJWT: templateJWT,
                templateJSON: jsonTemplateString
            }
            let result = await post_template(new_template);
            if(result.ok) {
                setError('')
                setSuccess("successfully added template")
            } else {
                setSuccess('')
                setError(result.error)
            }
        } catch(json_error) {
            setSuccess('');
            setError('error deserialising the json');
            return;
        }
    }

    // json schema placeholder
    const jsonSchemaString = "{\"id\":\"b9416efa-0c34-4550-955a-0c2115863b00\",\"name\":\"Yookr - Field Scout\",\"description\":\"\",\"externalDomain\":\"\",\"externalId\":\"\",\"configuration\":{},\"decode\":{\"method\":\"object\",\"encoding\":\"utf8\",\"methodOptions\":{},\"fields\":[{\"name\":\"biomass\",\"externalName\":\"biomass\"},{\"name\":\"nitrogen\",\"externalName\":\"nitrogen\"},{\"name\":\"soilMoisture\",\"externalName\":\"moistureContentCalculated\"},{\"name\":\"datetimeMeasure\",\"externalName\":\"datetimeMeasure\",\"calculate\":{\"function\":\"date\",\"options\":{\"parse\":true}}}]},\"encode\":{},\"sensors\":[{\"tag\":\"biomass\",\"sensorTemplateId\":\"7c4922d5-ea81-465c-9fbc-d67799f99062\"},{\"tag\":\"nitrogen\",\"sensorTemplateId\":\"65d66509-6f92-4e78-a5df-8d401971553e\"},{\"tag\":\"soilMoisture\",\"sensorTemplateId\":\"ec06ef53-145c-4f81-8cb1-8b7b35b21fab\"}],\"actuators\":[],\"metadata\":{},\"created\":\"2023-06-29T07:42:13.118Z\",\"modified\":\"2023-06-29T07:42:13.118Z\",\"deleted\":false,\"organizationId\":\"65585586-23de-4815-8709-4bacb6d4966b\"}";
    const jsonSchemaObject = JSON.parse(jsonSchemaString);
    const jsonPlaceHolder = JSON.stringify(jsonSchemaObject, null, 4);

    return (
        <Container 
            sx={{
                ...(success ? {border: "5px solid #1fbe21", borderRadius: "10px"} : {}),
                ...(error ? {border: "5px solid red", borderRadius: "10px"} : {})
            }} 
            className={styles.container} 
            maxWidth="lg"
        >
            { success && 
                <p className={styles.success}>{success}</p>
            }
            { error && 
                <p className={styles.error}>{error}</p>
            }
            <h2 className={styles.title}>Create Whysor Template</h2>
            <h3 className={styles.title}>Template JWT Token:</h3>
            <TextField 
                sx={{ width: "100%" }}
                label="JWT Token" 
                variant="filled"
                value={templateJWT}
                onChange={handleTemplateJWTChange}
            />
            <h3 className={styles.title}>Json Template:</h3>
            <TextField 
                sx={{ width: "100%", height: "300px", overflow: "scroll" }}
                label="Json Template"
                multiline
                variant="filled"
                placeholder={jsonPlaceHolder}
                value={templateJSON}
                onChange={handleTemplateJSONChange}
            />
            <Button
                sx={{ margin: "20px 0 20px 0" }}
                variant="contained"
                onClick={handleAddingTemplate}
            >Add Template</Button>
        </Container>
    )
}

export default Template;