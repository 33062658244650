// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Organisations_container__l-puR {
  margin: 20px 0 20px 0;
}

.Organisations_success__Jsqqk {
  margin-top: 20px;
  background-color: #1fbe21;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  color: white;
}

.Organisations_error__UbjdL {
  margin-top: 20px;
  background-color: red;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  color: white;
}

.Organisations_title__o2LBk {
  margin: 20px 0 20px 0;
}

.Organisations_button__SEVxs {
  display: block;
  width: 50%;
  margin: 0 25% 0 25%;
}`, "",{"version":3,"sources":["webpack://./src/components/organisations/Organisations.module.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,yBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AACJ;;AAEA;EACI,gBAAA;EACA,qBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,cAAA;EACA,UAAA;EACA,mBAAA;AACJ","sourcesContent":[".container {\n    margin: 20px 0 20px 0;\n}\n\n.success {\n    margin-top: 20px;\n    background-color: #1fbe21;\n    width: 100%;\n    padding: 10px;\n    box-sizing: border-box;\n    border-radius: 5px;\n    color: white;\n}\n\n.error {\n    margin-top: 20px;\n    background-color: red;\n    width: 100%;\n    padding: 10px;\n    box-sizing: border-box;\n    border-radius: 5px;\n    color: white;\n}\n\n.title {\n    margin: 20px 0 20px 0;\n}\n\n.button {\n    display: block;\n    width: 50%;\n    margin: 0 25% 0 25%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Organisations_container__l-puR`,
	"success": `Organisations_success__Jsqqk`,
	"error": `Organisations_error__UbjdL`,
	"title": `Organisations_title__o2LBk`,
	"button": `Organisations_button__SEVxs`
};
export default ___CSS_LOADER_EXPORT___;
