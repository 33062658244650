// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Endpoint_container__tyWnt {
  margin: 20px 0 20px 0;
}

.Endpoint_title__gUcXD {
  margin-top: 20px;
  margin-bottom: 20px;
}

.Endpoint_jsoncontainer__FDseC {
  border: 2px solid #c8acfb;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(200, 172, 251, 0.5);
  height: 500px;
  overflow: scroll;
  border-radius: 10px;
}

.Endpoint_success__lpyPk {
  margin-top: 20px;
  background-color: #1fbe21;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  color: white;
}

.Endpoint_error__cTR6n {
  margin-top: 20px;
  background-color: red;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/endpoint/Endpoint.module.scss","webpack://./src/theme/colours.scss"],"names":[],"mappings":"AAEA;EACI,qBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,0CCZmB;EDanB,aAAA;EACA,gBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,yBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AADJ;;AAIA;EACI,gBAAA;EACA,qBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AADJ","sourcesContent":["@import '../../theme/colours.scss';\n\n.container {\n    margin: 20px 0 20px 0;\n}\n\n.title {\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n\n.jsoncontainer {\n    border: 2px solid $colour-purple;\n    padding: 20px;\n    box-sizing: border-box;\n    background-color: $colour-purple-faded;\n    height: 500px;\n    overflow: scroll;\n    border-radius: 10px;\n}\n\n.success {\n    margin-top: 20px;\n    background-color: #1fbe21;\n    width: 100%;\n    padding: 10px;\n    box-sizing: border-box;\n    border-radius: 5px;\n    color: white;\n}\n\n.error {\n    margin-top: 20px;\n    background-color: red;\n    width: 100%;\n    padding: 10px;\n    box-sizing: border-box;\n    border-radius: 5px;\n    color: white;\n}","$colour-orange: #ea5b0c;\n$colour-orange-faded: rgba(234, 91, 12, 0.5);\n$colour-purple:  #c8acfb;\n$colour-purple-faded:  rgba(200, 172, 251, 0.5);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Endpoint_container__tyWnt`,
	"title": `Endpoint_title__gUcXD`,
	"jsoncontainer": `Endpoint_jsoncontainer__FDseC`,
	"success": `Endpoint_success__lpyPk`,
	"error": `Endpoint_error__cTR6n`
};
export default ___CSS_LOADER_EXPORT___;
