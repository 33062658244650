import { Box, Button, Container, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"

import styles from "./Connect.module.scss";
import { ChangeEvent, useEffect, useState } from "react";
import { IFieldAdd, INewYookrEndpoint, IOrganisation } from "../../types/apitypes";
import { fetch_organisations, fetch_templates, post_yookr_path_schema, post_new_yookr_path } from "../../api/api";

const Connect = () => {

    // variables
    const [organisations, setOrganisations] = useState<IOrganisation[]>([]);
    const [templates, setTemplates] = useState<string[]>([]);
    const [mappings, setMappings] = useState<string[]>([]);
    const [addedMappings, setAddedMappings] = useState<Map<string, string>>(new Map());
    const [success, setSuccess] = useState('');
    const [error, setError] = useState("");

    // handle selecting organisation
    const [selectedOrg, setSelectedOrg] = useState('');
    const handleSelectOrg = (event: SelectChangeEvent) => {
        setSelectedOrg(event.target.value as string);
        setYookrPath('/' + (organisations.find(org => org.organisationID === event.target.value)?.name || "undefined") + "/")
    }

    // handle typing new endpoint
    const [yookrPath, setYookrPath] = useState<string>('');
    const handleYookrPathChange = (event: ChangeEvent<HTMLInputElement>) => {
        setYookrPath(event.target.value);
    }

    // handle selecting template
    const [selectedTemp, setSelectedTemp] = useState('');
    const handleSelectTemp = (event: SelectChangeEvent) => {
        setSelectedTemp(event.target.value as string);
    }

    // handle changing json schema 
    const [jsonSchema, setJsonSchema] = useState('');
    const handleSchemaChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputJsonSchema = event.target.value;
        setJsonSchema(inputJsonSchema);
    }

    // handle adding to mappings
    const [mappingName, setMappingName] = useState<string>('');
    const [mappingValue, setMappingValue] = useState<string>('');
    const [mappingConditionField, setMappingConditionField] = useState('');
    const [mappingConditionValue, setMappingConditionValue] = useState('');
    const handleMappingNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setMappingName(event.target.value);
    }
    const handleMappingValueChange = (event: SelectChangeEvent) => {
        setMappingValue(event.target.value as string);
    }
    const handleMappingConditionFieldChange = (event: SelectChangeEvent) => {
        setMappingConditionField(event.target.value);
    }
    const handleMappingConditionValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        setMappingConditionValue(event.target.value);
    }
    const handleAddingMapping = () => {
        addFieldValuePair(mappingName, mappingValue);
        setMappingName('');
        setMappingValue('');
        setMappingConditionField('');
        setMappingConditionValue('');
    }
    const addFieldValuePair = (field: string, value: string) => {

        if (mappingConditionField !== "none" && mappingConditionField !== "" && mappingConditionValue !== "") {
            value = value + "{" + mappingConditionField + ":" + mappingConditionValue + "}";
        }
        const newMap = new Map(addedMappings);
        newMap.set(field, value);
        setAddedMappings(newMap);

    }

    // handle adding endpoint
    const handleAddingEndpoint = async () => {
        try {
            const jsonSchemaObject = JSON.parse(jsonSchema);
            const jsonSchemaString = JSON.stringify(jsonSchemaObject);
            const newYookrEndpoint: INewYookrEndpoint = {
                yookrpath: yookrPath,
                organisationID: selectedOrg,
                templateID: selectedTemp,
                jsonSchema: jsonSchemaString,
                mappings: Array.from(addedMappings.entries()).map(([field, value]): IFieldAdd => ({
                    field,
                    value,
                }))
            }
            const yookrPathResult = await post_new_yookr_path(newYookrEndpoint);
            if (yookrPathResult.ok) {
                setError('')
                setSuccess('succesfully posted new yookr path')
            } else {
                setSuccess('')
                setError(yookrPathResult.error)
            }
        } catch (err) {
            setError('failed to deserialise json schema')
        }
    }

    // json schema placeholder
    const jsonSchemaString = "{\"$schema\":\"http:\\/\\/json-schema.org\\/draft-07\\/schema#\",\"type\":\"object\",\"properties\":{\"deviceID\":{\"type\":\"string\"},\"data\":{\"type\":\"array\",\"items\":{\"type\":\"object\",\"properties\":{\"mean\":{\"type\":\"number\"}},\"required\":[\"mean\"]}}},\"required\":[\"deviceID\",\"data\"]}";
    const jsonSchemaObject = JSON.parse(jsonSchemaString);
    const jsonPlaceHolder = JSON.stringify(jsonSchemaObject, null, 4);

    useEffect(() => {

        const fetchData = async () => {
            const [organisationResult, templateResult] = await Promise.all([
                fetch_organisations(),
                fetch_templates()
            ]);
            if (organisationResult.ok){
                setOrganisations(organisationResult.value);
                if (templateResult.ok) {
                    setTemplates(templateResult.value);
                } else {
                    setError(templateResult.error);
                }
            } else {
                setError(organisationResult.error);
            }
        }
        fetchData();

        const postData = async () => {
            console.log("hello");
            if (jsonSchema !== '') {
                const jsonSchemaString = JSON.stringify(jsonSchema.replaceAll("\n", '').replaceAll("\t", '').replaceAll(" ", ''));
                const mappingResult = await post_yookr_path_schema(jsonSchemaString)
                if (mappingResult.ok) {
                    setMappings(mappingResult.value);
                } else {
                    setError(mappingResult.error);
                }
            }
        }
        postData();

    }, [jsonSchema])

    return (
        <Container 
            sx={{
                ...(success ? {border: "5px solid #1fbe21", borderRadius: "10px"} : {}),
                ...(error ? {border: "5px solid red", borderRadius: "10px"} : {})
            }} 
            className={styles.container} 
            maxWidth="lg"
        >
            { success && 
                <p className={styles.success}>{success}</p>
            }
            { error && 
                <p className={styles.error}>{error}</p>
            }
            <h2 className={styles.title}>Organisation:</h2>
            <FormControl fullWidth>
                <InputLabel>Organisation</InputLabel>
                <Select
                    value={selectedOrg}
                    label="Organisation"
                    onChange={handleSelectOrg}
                >
                    {organisations.map((org) => (
                        <MenuItem key={org.organisationID} value={org.organisationID}>{org.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <h2 className={styles.title}>New Connector Endpoint:</h2>
            <TextField 
                sx={{ width: "100%" }}
                id="filled-basic" 
                label="Connector Endpoint" 
                variant="filled"
                value={yookrPath}
                onChange={handleYookrPathChange}
            />
            <h2 className={styles.title}>Template ID:</h2>
            <FormControl fullWidth>
                <InputLabel>Template ID</InputLabel>
                <Select
                    value={selectedTemp}
                    label="Template"
                    onChange={handleSelectTemp}
                >
                    {templates.map((temp) => (
                        <MenuItem key={temp} value={temp}>{temp}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <h2 className={styles.title}>Json Schema:</h2>
            <TextField
                sx={{ width: "100%", maxHeight: "300px", overflow: "scroll" }}
                label="Json Schema"
                placeholder={jsonPlaceHolder}
                multiline
                variant="filled"
                value={jsonSchema}
                onChange={handleSchemaChange}
            />
            <h2 className={styles.title}>Field Mapping:</h2>
            <Container>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: "100%" }} aria-label="mapped fields table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Whysor Field Name</TableCell>
                                <TableCell align="right">Mapping From Schema</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.from(addedMappings.entries()).map(([field, mapping]) => (
                                <TableRow
                                    key={field}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{field}</TableCell>
                                    <TableCell align="right">{mapping}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box display="flex" alignItems="center">
                    <TextField 
                        sx={{ flex: 1 }}
                        label="Mapping Field Name"
                        variant="filled"
                        value={mappingName}
                        onChange={handleMappingNameChange}
                    />
                    <FormControl sx={{ flex: 1 }} fullWidth>
                        <InputLabel>Field Mapping</InputLabel>
                        <Select
                            value={mappingValue}
                            label="Field Mapping"
                            onChange={handleMappingValueChange}
                        >
                            {mappings.map((map) => (
                                <MenuItem key={map} value={map}>{map}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ flex: 1 }} fullWidth>
                        <InputLabel>Condition Field</InputLabel>
                        <Select
                            value={mappingConditionField}
                            label="Condition Field"
                            onChange={handleMappingConditionFieldChange}
                        >
                            {mappings.map((map) => (
                                <MenuItem key={map} value={map}>{map}</MenuItem>
                            ))}
                            <MenuItem key="none" value="none">None</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField 
                        sx={{ flex: 1 }}
                        label="Condition Field Value"
                        variant="filled"
                        value={mappingConditionValue}
                        onChange={handleMappingConditionValueChange}
                    />
                </Box>
                <Button
                    sx={{ width: "100%", marginTop: "10px" }}
                    variant="contained"
                    onClick={handleAddingMapping}
                >Add Mapping</Button>
            </Container>
            <Button
                sx={{ margin: "20px 0 20px 0" }}
                onClick={handleAddingEndpoint} 
                variant="contained"
            >Add Connector Endpoint</Button>
        </Container>
    )
}

export default Connect;