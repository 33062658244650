export function encodeUrl(url: string)
    : string 
{
    let encoded_url = btoa(url).replace(/\+/g, '-').replace(/\//g, '_');
    return encoded_url;
}

export function decodeUrl(url: string)
    : string
{
    let decoded_url = atob(url.replace(/-/g, '+').replace(/_/g, '/'));
    return decoded_url;
}