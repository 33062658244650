// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Migrate_container__RwLBT {
  margin: 20px 0 20px 0;
}

.Migrate_title__fOXQ1 {
  margin: 20px 0 20px 0;
}

.Migrate_success__2wPkk {
  margin-top: 20px;
  background-color: #1fbe21;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  color: white;
}

.Migrate_error__WDcV1 {
  margin-top: 20px;
  background-color: red;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/migrate/Migrate.module.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,yBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AACJ;;AAEA;EACI,gBAAA;EACA,qBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AACJ","sourcesContent":[".container {\n    margin: 20px 0 20px 0;\n}\n\n.title {\n    margin: 20px 0 20px 0;\n}\n\n.success {\n    margin-top: 20px;\n    background-color: #1fbe21;\n    width: 100%;\n    padding: 10px;\n    box-sizing: border-box;\n    border-radius: 5px;\n    color: white;\n}\n\n.error {\n    margin-top: 20px;\n    background-color: red;\n    width: 100%;\n    padding: 10px;\n    box-sizing: border-box;\n    border-radius: 5px;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Migrate_container__RwLBT`,
	"title": `Migrate_title__fOXQ1`,
	"success": `Migrate_success__2wPkk`,
	"error": `Migrate_error__WDcV1`
};
export default ___CSS_LOADER_EXPORT___;
