import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import styles from './Endpoint.module.scss';
import { useEffect, useState } from "react";
import { fetch_organisation_path_parsed } from "../../api/api";
import { decodeUrl } from "../../services/urlencoder";

const Endpoint = () => {

  const { endname } = useParams();
  
  const [formattedOrgData, setFormattedOrgData] = useState("");
  const [formattedWhysorData, setformattedWhysorData] = useState("");
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {

    const fetchData = async () => {
      const result = await fetch_organisation_path_parsed(endname ?? "");

      if (result.ok) {
        setError('')
        setSuccess('successfully parsed data')
        setFormattedOrgData(JSON.stringify(result.value.orgdata, null, 4));
        setformattedWhysorData(JSON.stringify(result.value.whysordata, null, 4));
      } else {
        setSuccess('')
        setError(result.error)
      }
    }
    fetchData();

  }, [endname])

  return (
    <Container className={styles.container} maxWidth="lg">
        <h2 className={styles.title}>{decodeUrl(endname || '')}</h2>
        <h3 className={styles.title}>Mappings for organisation to whysor</h3>
        { success && 
            <p className={styles.success}>{success}</p>
        }
        { error && 
            <p className={styles.error}>{error}</p>
        }
        <Container sx={{ display: "flex", marginTop: "20px", "&.MuiContainer-maxWidthLg": {paddingLeft: "0", paddingRight: "0"} }} maxWidth="lg">
          <Container 
            sx={{ 
              margin: "0 5px 0 5px",
              ...(success ? {border: "2px solid #1fbe21", backgroundColor: "#1fbe21"} : {}),
              ...(error ? {border: "2px solid red", backgroundColor: "red"} : {}) 
            }} className={styles.jsoncontainer}>
            <pre>{formattedOrgData}</pre>
          </Container>
          <Container 
            sx={{ 
              margin: "0 5px 0 5px",
              ...(success ? {border: "2px solid #1fbe21", backgroundColor: "#1fbe21"} : {}),
              ...(error ? {border: "2px solid red", backgroundColor: "red"} : {}) 
            }} className={styles.jsoncontainer}>
            <pre>{formattedWhysorData}</pre>
          </Container>
        </Container>
    </Container>
  );
};

export default Endpoint;