import { ChangeEvent, useEffect, useState } from "react";
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";
import React from "react";

import styles from "./Organisations.module.scss";
import { fetch_organisations, post_new_organisation_token, post_new_organisation_login, post_new_organisation } from "../../api/api";
import { INewOrganisation, INewOrganisationLogin, INewOrganisationToken, IOrganisation } from "../../types/apitypes";
import { encodeUrl } from "../../services/urlencoder";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Organisations = () => {

    const [organisations, setOrganisations] = useState<IOrganisation[]>([]);
    const [refresh, setRefresh] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState("");

    // handle organisation name
    const [orgName, setOrgName] = useState<string>('');
    const handleOrgNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOrgName(event.target.value);
    }

    // handle authentication type
    const [authType, setAuthType] = useState<string>('');
    const handleAuthTypeChange = (event: SelectChangeEvent) => {
        setAuthType(event.target.value);
        if (event.target.value === "header_auth") {
            setAuthValue("Bearer")
        }
    }

    // handle authentication value
    const [authValue, setAuthValue] = useState<string>('');
    const handleAuthValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        setAuthValue(event.target.value);
    }

    // handle login path
    const [loginEndpoint, setLoginEndpoint] = useState<string>('');
    const handleLoginEndpointChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLoginEndpoint(event.target.value)
    }

    // handle login json
    const [loginJson, setLoginJson] = useState<string>('');
    const handleLoginJsonChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLoginJson(event.target.value)
    }

    // handle token field
    const [tokenField, setTokenField] = useState<string>('');
    const [tokenFields, setTokenFields] = useState<string[]>([]);
    const handleTokenFieldChange = (event: SelectChangeEvent) => {
        setTokenField(event.target.value);
    }

    // handle token
    const [token, setToken] = useState<string>('');
    const handleTokenChange = (event: ChangeEvent<HTMLInputElement>) => {
        setToken(event.target.value);
    }

    const handleAddingOrganisation = async () => {
        const newOrganisation: INewOrganisation = {
            organisationName: orgName
        }
        const result = await post_new_organisation(newOrganisation);
        if(result.ok){
            setError('')
            setSuccess("successefully added organisation")
        } else {
            setSuccess('')
            setError(result.error)
        }
        setRefresh(!refresh); // change value to trigger refresh
    }

    const handleAddingOrganisationToken = async () => {
        const newOrganisation: INewOrganisationToken = {
            organisationName: orgName,
            authType: authType,
            authValue: authValue,
            token: token
        }
        const result = await post_new_organisation_token(newOrganisation);
        if(result.ok){
            setError('')
            setSuccess("successefully added organisation using token")
        } else {
            setSuccess('')
            setError(result.error)
        }
        setRefresh(!refresh); // change value to trigger refresh
    }

    // handle adding organisation
    const handleAddingOrganisationLogin = async () => {
        let jsonLoginString = JSON.stringify(loginJson.replaceAll("\n", '').replaceAll("\t", '').replaceAll(" ", '')).replace(/\\/g, '');
        jsonLoginString = jsonLoginString.slice(1, -1);
        const newOrganisation: INewOrganisationLogin = {
            organisationName: orgName,
            authType: authType,
            authValue: authValue,
            loginEndpoint: loginEndpoint,
            loginJson: jsonLoginString,
            tokenField: tokenField
        }
        let result = await post_new_organisation_login(newOrganisation);
        if (result.ok) {
            setError('')
            setSuccess("successefully added organisation using login credentials")
        } else {
            setSuccess('')
            setError(result.error)
        }
        setRefresh(!refresh); // change value to trigger refresh
    }

    // json schema placeholder
    const jsonSchemaString = "{\"username\":\"john\",\"password\":\"hello-world\"}";
    const jsonSchemaObject = JSON.parse(jsonSchemaString);
    const jsonPlaceHolder = JSON.stringify(jsonSchemaObject, null, 4);

    // handle tabs
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {

        const fetchTokenFields = async () => {
            let jsonLoginString = JSON.stringify(loginJson.replaceAll("\n", '').replaceAll("\t", '').replaceAll(" ", '')).replace(/\\/g, '');
            jsonLoginString = jsonLoginString.slice(1, -1);
            const newOrganisation: INewOrganisationLogin = {
                organisationName: orgName,
                authType: authType,
                authValue: authValue,
                loginEndpoint: loginEndpoint,
                loginJson: jsonLoginString,
                tokenField: ""
            }
            const result = await post_new_organisation_login(newOrganisation);
            if (result.ok) {
                setTokenFields(result.value);
            } else {
                setSuccess('')
                setError(result.error)
            }
        }

        const fetchData = async () => {
            const [result] = await Promise.all([
                fetch_organisations()
            ]);
            if(result.ok){
                setOrganisations(result.value);
            } else {
                setError(result.error);
            }
        }
        fetchData();
        if (orgName !== "" && authType !== "" && authValue !== "" && loginEndpoint !== "" && loginJson !== "") {
            fetchTokenFields();
        }

    }, [orgName, authType, authValue, loginEndpoint, loginJson, tokenField, refresh])

    return (
        <Container 
            sx={{
                ...(success ? {border: "5px solid #1fbe21", borderRadius: "10px"} : {}),
                ...(error ? {border: "5px solid red", borderRadius: "10px"} : {})
            }} 
            className={styles.container} 
            maxWidth="lg"
        >
            { success && 
                <p className={styles.success}>{success}</p>
            }
            { error && 
                <p className={styles.error}>{error}</p>
            }
            <h2 className={styles.title}>Organisations:</h2>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="organisation table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Organisation ID</TableCell>
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Endpoints</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organisations.map((organisation) => (
                            <TableRow
                                key={organisation.organisationID.toString()}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{organisation.organisationID}</TableCell>
                                <TableCell style={{ textTransform: 'capitalize' }} align="right">{organisation.name}</TableCell>
                                <TableCell align="right">
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 400 }} aria-label="endpoint table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ width: "40%" }}>Yookr's Endpoint</TableCell>
                                                    <TableCell style={{ textTransform: 'capitalize', width: "60%" }}>{organisation.name}'s Endpoint</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {organisation.yookrPaths.map((yookrPath) => (
                                                    <TableRow
                                                        key={yookrPath.yookrPath}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row" sx={{ width: "40%" }}>{yookrPath.yookrPath}</TableCell>
                                                        <TableCell sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center"}}>
                                                            {yookrPath.orgPaths.map((orgPath) => (
                                                                <Link 
                                                                    to={`/orgpath/${encodeUrl(orgPath.orgPath)}`} key={orgPath.orgPath.toString()}
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <Button sx={{ margin: "10px 0 10px 0", textTransform: "none", width: "100%"}} variant="contained">{orgPath.orgPath}</Button>
                                                                </Link>
                                                            ))}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <h3 className={styles.title}>Create Organisation:</h3>
            <Container >
                <TextField 
                    sx={{ width: "100%" }}
                    label="Organisation Name"
                    variant="filled"
                    value={orgName}
                    onChange={handleOrgNameChange}
                />
                <Button
                    sx={{ marginTop: "20px", display: "block" }}
                    variant="contained"
                    onClick={handleAddingOrganisation}
                >Add Organisation</Button>
                <FormControl sx={{ marginTop: "20px", width: "50%" }}>
                    <InputLabel>Authentication Type</InputLabel>
                    <Select
                        value={authType}
                        label="Authentication Type"
                        onChange={handleAuthTypeChange}
                    >
                        <MenuItem key="header_auth" value="header_auth">Authorization Header</MenuItem>
                        <MenuItem key="param_auth" value="param_auth">Parameter Field</MenuItem>
                    </Select>
                </FormControl>
                <TextField 
                    sx={{ marginTop: "20px", width: "50%" }}
                    label="Authentication Field"
                    variant="filled"
                    value={authValue}
                    onChange={handleAuthValueChange}
                />
                <Box sx={{ width: '100%', marginTop: "20px" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Token" {...a11yProps(0)} />
                            <Tab label="Login" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <TextField 
                            sx={{ width: "100%" }}
                            label="Token"
                            variant="filled"
                            value={token}
                            onChange={handleTokenChange}
                        />
                        <Button
                            sx={{ marginTop: "20px" }}
                            variant="contained"
                            onClick={handleAddingOrganisationToken}
                        >Add Organisation</Button>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <TextField 
                            sx={{ width: "100%" }}
                            label="Login Endpoint"
                            variant="filled"
                            value={loginEndpoint}
                            onChange={handleLoginEndpointChange}
                        />
                        <TextField 
                            sx={{ width: "100%", overflow: "scroll", marginTop: "20px"}}
                            label="Login Json (this will be encrypted)"
                            variant="filled"
                            placeholder={jsonPlaceHolder}
                            multiline
                            value={loginJson}
                            onChange={handleLoginJsonChange}
                        />
                        <FormControl sx={{ marginTop: "20px"}} fullWidth>
                            <InputLabel>Token field</InputLabel>
                            <Select
                                value={tokenField}
                                label="Token Field"
                                onChange={handleTokenFieldChange}
                            >
                                {tokenFields.map((field) => (
                                    <MenuItem key={field} value={field}>{field}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            sx={{ marginTop: "20px" }}
                            variant="contained"
                            onClick={handleAddingOrganisationLogin}
                        >Add Organisation</Button>
                    </CustomTabPanel>
                </Box>
            </Container>
        </Container>
    )
}

export default Organisations;