
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import styles from './Schema.module.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import { INewSchemaEndpoint, INewSchemaData, IOrganisation } from '../../types/apitypes';
import { fetch_organisations, post_shema_generate_endpoint_mappings, post_shema_generate_data_mappings, post_shema_generate_endpoint, post_shema_generate_data } from '../../api/api';
import React from 'react';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Schema = () => {

    // variables
    const [schema, setSchema] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState("");

    // handle selecting organisation
    const [organisations, setOrganisations] = useState<IOrganisation[]>([]);
    const [selectedOrg, setSelectedOrg] = useState('');
    const handleSelectOrg = (event: SelectChangeEvent) => {
        setSelectedOrg(event.target.value as string);
    };

    // handle endpoint change
    const [orgPath, setOrgPath] = useState('');
    const handleEndpointChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOrgPath(event.target.value);
    }

    // handle selecting data type
    const [dataType, setDataType] = useState('json');
    const handleSelectDataType = (event: SelectChangeEvent) => {
        setDataType(event.target.value);
    }

    // handle data change
    const [data, setData] = useState('');
    const handleDataChange = (event: ChangeEvent<HTMLInputElement>) => {
        setData(event.target.value);
    }

    // handle selecting fields
    const [fields, setFields] = useState<string[]>([]);
    const [selectedFields, setSelectedFields] = useState<string[]>([]);
    const [field, setField] = useState('');
    const handleFieldChange = (event: SelectChangeEvent) => {
        setField(event.target.value);
    }
    const handleAddingField = () => {
        if (field !== ''){
            setSelectedFields(selectedFields => [...selectedFields, field]);
        }
    }

    // handle tabs
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    // handle generate schema
    const handleGenerateSchema = async () => {
        if (selectedFields.length > 0) {

            if (selectedOrg != '' && orgPath != '' && dataType != '') {
                const schema_generate: INewSchemaEndpoint = {
                    organisationID: selectedOrg,
                    orgPath: orgPath,
                    dataType: dataType,
                    mappings: selectedFields
                }
                const schemaResult = await post_shema_generate_endpoint(schema_generate);
                if (schemaResult.ok) {
                    setError('')
                    setSuccess("successfully generated schema")
                    const jsonSchemaObject = JSON.parse(schemaResult.value);
                    setSchema(JSON.stringify(jsonSchemaObject, null, 4));
                } else {
                    setSuccess('')
                    setError('failed to generate schema')
                }
            }

            if (selectedOrg != '' && data != '') {
                const schema_generate: INewSchemaData = {
                    organisationID: selectedOrg,
                    data: data,
                    mappings: selectedFields
                }
                const schemaResult = await post_shema_generate_data(schema_generate);
                if (schemaResult.ok) {
                    setError('')
                    setSuccess("successfully generated schema")
                    const jsonSchemaObject = JSON.parse(schemaResult.value);
                    setSchema(JSON.stringify(jsonSchemaObject, null, 4));
                } else {
                    setSuccess('')
                    setError('failed to generate schema')
                }
            }

            
        } else {

        }
    }

    useEffect(() => {

        const fetchSchemaFields = async () => {

            if (selectedOrg != '' && orgPath !== '' && dataType !== '') {
                const shema_generate: INewSchemaEndpoint = {
                    organisationID: selectedOrg,
                    orgPath: orgPath,
                    dataType: dataType,
                    mappings: []
                };
                return await post_shema_generate_endpoint_mappings(shema_generate);
            }

            if (selectedOrg != '' && data !== '') {
                const shema_generate: INewSchemaData = {
                    organisationID: selectedOrg,
                    data: data,
                    mappings: []
                };
                return await post_shema_generate_data_mappings(shema_generate);
            }

            return { ok: true, value: [] }
            
        }

        const fetchData = async () => {
            const [organisationsResult, schemaGenerateResult] = await Promise.all([
                fetch_organisations(),
                fetchSchemaFields()
            ]);
            if (organisationsResult.ok){
                setOrganisations(organisationsResult.value);
                if (schemaGenerateResult.ok){
                    setFields(schemaGenerateResult.value);
                }
            } else {
                setError(organisationsResult.error);
            }
        }
        fetchData();

    }, [orgPath, dataType, selectedOrg, data])

    return (
        <Container
            className={styles.container} 
            maxWidth="lg"
        >
            { success && 
                <p className={styles.success}>{success}</p>
            }
            { error && 
                <p className={styles.error}>{error}</p>
            }
            <h2 className={styles.title}>Organisation:</h2>
            <FormControl fullWidth>
                <InputLabel>Organisation</InputLabel>
                <Select
                    value={selectedOrg}
                    label="Organisation"
                    onChange={handleSelectOrg}
                >
                    {organisations.map((org) => (
                        <MenuItem key={org.organisationID} value={org.organisationID}>{org.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box sx={{ width: '100%', marginTop: "20px" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Endpoint" {...a11yProps(0)} />
                        <Tab label="Data" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <h3 className={styles.title}>Example Endpoint:</h3>
                    <Container maxWidth="lg">
                        <TextField 
                            sx={{ width: "80%" }}
                            label="Endpoint"
                            variant="filled"
                            value={orgPath}
                            onChange={handleEndpointChange}
                        />
                        <FormControl fullWidth
                            sx={{ width: "20%" }}
                        >
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={dataType}
                                label="Type"
                                onChange={handleSelectDataType}
                            >
                                <MenuItem value="json">JSON</MenuItem>
                                <MenuItem value="csv">CSV</MenuItem>
                                <MenuItem value="influxdb">InfluxDB</MenuItem>
                                <MenuItem value="titledfields">Titled Fields</MenuItem>
                            </Select>
                        </FormControl>
                    </Container>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <h3 className={styles.title}>Example Data:</h3>
                    <TextField
                        sx={{ width: "100%", height: "300px", overflow: "scroll" }}
                        label="Data"
                        variant="filled"
                        multiline
                        onChange={handleDataChange}
                        value={data}
                    />
                </CustomTabPanel>
            </Box>
            <h3 className={styles.title}>Fields to Capture:</h3>
            <Container>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: "100%" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Field Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedFields.map((selectedField) => (
                                <TableRow key={selectedField}>
                                    <TableCell>{selectedField}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <FormControl sx={{ marginTop: "20px" }} fullWidth>
                    <InputLabel>Field</InputLabel>
                    <Select
                        value={field}
                        label="Field"
                        onChange={handleFieldChange}
                    >
                        {fields.map((fieldName) => (
                            <MenuItem key={fieldName} value={fieldName}>{fieldName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    sx={{ width: "100%", marginTop: "10px" }}
                    variant="contained"
                    onClick={handleAddingField}
                >Add Field to Schema</Button>
            </Container>
            <Button
                sx={{ marginTop: "20px"}}
                variant='contained'
                onClick={handleGenerateSchema}
            >Generate Schema</Button>
            <TextField 
                sx={{ 
                    width: "100%", 
                    marginTop: "20px", 
                    height: "300px", 
                    overflow: "scroll"
                }}
                InputProps={{
                    style: {
                        color: "black",
                        ...(success ? { backgroundColor: '#1fbe21' } : {}),
                        ...(error ? { backgroundColor: 'red', opacity: '0.5' } : {})
                    }
                }}
                label="Schema"
                multiline
                variant="filled"
                value={schema}
            />
        </Container>
    )
}

export default Schema;